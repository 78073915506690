// Primary Blue	HEX #4F758B RGB 79/117/139
// Primary Red	HEX #C8102E RGB 200/16/46
// Action Blue	HEX #0099CC RGB 0/153/204
// Body Copy	HEX #54585A RGB 84/88/90
// Supplemental Gray	HEX #9EA2A2 RGB 158/162/162
// Background Gray	HEX #F2F2F0 RGB 242/242/240

const color = {
  primary: {
    // Primary colors #FF1940 hsl(350, 100%, 55%) to #C8102E hsl(350, 85%, 42%)
    main: "hsl(350, 85%, 42%)", // 	#c8102e
    dark: "hsl(350, 85%, 40%)", //"#BD0F2B",
    darker: "hsl(350, 84%, 36%)", // "#a80f28",
    contrastText: "hsl(0, 0%, 100%)", //"#FFFFFF",
  },

  secondary: {
    // Secondary colors #00BFFF hsl(195, 100%, 50%) to #0099CC hsl(195, 100%, 40%)
    main: "hsl(195, 100%, 40%)", // "#0099CC",
    light: "hsl(188, 92%, 95%)", // "#E7FBFE",
    skyBlue: "hsl(195, 100%, 44%)", // "#00a9e0",
    onHover: "hsl(195, 100%, 40%)", // "#09c",
  },

  tertiary: {
    // Tertiary colors #6696B3 	hsl(203, 34%, 55%) to #4F758B hsl(202, 28%, 43%)
    main: "#032b42", // "#032b42"
    dark: "hsl(202, 28%, 43%)", //"#4F758B",
    darker: "hsl(202, 31%, 37%)", // "#42677c",
    grayTone: "hsl(181, 7%, 48%)", //"#727D83",
    grayToneDark: "hsl(181, 1%, 28%)", // "#424648",
  },

  text: {
    primary: "hsl(0, 0%, 15%)", // "#262626",
    secondary: "hsl(0, 0%, 20%)", // "#333333",
    tertiary: "hsl(0, 0%, 53%)", // "#888",
  },

  grayTone: {
    copyright: "#666",
    bodyCopy: "hsl(200, 3%, 34%)", // "#54585a",

    // used for primary icons
    supplemental: "hsl(180, 2%, 63%)", // "#9ea2a2",

    // Background Gray
    background: "hsl(60, 7%, 95%)", // "#F2F2F0",
    lighterBackground: "hsl(60, 11%, 96%)", // "#F7F7F5",

    subTitle: "hsl(160, 1%, 45%)", // "#707372",

    white: "hsl(0, 0%, 100%)", // "#ffffff",
    filterGridBorder: "hsl(120, 2%, 80%)", //  "#caccca",
    onTableRowHover: "hsl(194, 68%, 94%)", // "#E5F5FA",
    black: "hsl(0, 0%, 0%)", // "#000000",
    footergray: "#edf0f2",
    iconText: "#4f758b",
    iconButtonBorder: "rgba(79, 117, 139, 0.2)",
  },
};

export { color };
