import { createTheme, ThemeOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import React from "react";
import { color } from "./color";
import { font } from "./font";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    gridRegularText: true;
    gridLinkBoldText: true;
    gridSubtitleText: true;
    carouselItemTitleText: true;
    carouselItemDateText: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  gridRegularText: React.CSSProperties;
  gridSubtitleText: React.CSSProperties;
  gridLinkBoldText: React.CSSProperties;
  carouselItemTitleText: React.CSSProperties;
  carouselItemDateText: React.CSSProperties;
}
const theme = createTheme({
  // spacing: (factor) => `${0.25 * factor}rem`,
  spacing: (factor: number) => `${0.25 * factor * 16}px`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 930,
      lg: 1100,
      xl: 1392,
    },
  },
  palette: {
    primary: {
      main: color.primary.main,
      dark: color.primary.dark,
      contrastText: color.primary.contrastText,
    },
    secondary: {
      light: color.secondary.light,
      main: color.secondary.main,
      dark: color.tertiary.dark,
      contrastText: color.primary.contrastText,
    },
    text: {
      primary: color.text.primary,
      secondary: color.text.secondary,
    },
    background: {
      default: color.grayTone.white,
    },
  },
  typography: {
    fontFamily: font.primary,
    gridLinkBoldText: {
      fontFamily: font.primary,
      fontSize: "1.2rem",
      fontWeight: 600,
      color: color.tertiary.main,
    },
    gridRegularText: {
      fontFamily: font.primary,
      fontSize: "1.2rem",
      color: color.text.primary,
    },
    gridSubtitleText: {
      fontFamily: font.primary,
      fontSize: "1rem",
      color: color.text.primary,
    },
    h1: {
      fontFamily: font.primary,
      fontSize: "clamp(2.3rem, 3vw + 1rem, 3.75rem)",
      fontWeight: 400,
      lineHeight: 1.2,
      letterSpacing: "0",
      color: color.text.primary,
    },
    h2: {
      fontFamily: font.primary,
      // fontSize: "1.5rem",
      fontSize: "clamp(2rem, 2vw + 1rem, 2.75rem)",
      fontWeight: 500,
      whiteSpace: "pre-wrap",
      lineHeight: 1.5,
    },
    h3: {
      fontFamily: font.primary,
      // fontSize: "1.25rem",
      fontSize: "clamp(1rem, 2vw + 1rem, 1.25rem)",
      color: color.text.primary,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontFamily: font.secondary,
      fontSize: "1.0rem",
      fontWeight: 400,
      color: color.text.secondary,
      lineHeight: 2,
    },
    h5: {
      fontFamily: font.secondary,
      fontSize: "1.0rem",
      fontWeight: 400,
      color: color.text.secondary,
      lineHeight: 2,
    },
    h6: {},
    subtitle1: {
      fontFamily: font.primary,
      color: color.text.primary,
      fontWeight: 500,
      fontSize: "1.1rem",
      lineHeight: 1.5,
    },
    subtitle2: {
      fontFamily: font.tertiary,
      fontSize: "1rem",
      color: color.text.primary,
    },
    body1: {
      fontFamily: font.secondary,
      color: color.text.primary,
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 2.0,
      whiteSpace: "pre-wrap",
      marginBottom: "10px",
    },
    body2: {
      fontFamily: font.secondary,
      color: color.grayTone.bodyCopy,
      fontWeight: 400,
      fontSize: "1rem",
      whiteSpace: "pre-wrap",
    },
    button: {
      fontFamily: font.primary,
      fontSize: "1.2rem",
      lineHeight: "28px",
      textTransform: "none",
      letterSpacing: "0.45712px",
    },
    caption: {
      fontFamily: font.primary,
      fontWeight: 400,
      fontSize: "1rem",
    },
    overline: {
      fontFamily: font.primary,
      fontSize: "1rem",
      fontWeight: 400,
    },
    carouselItemTitleText: {
      fontFamily: font.primary,
      color: color.text.primary,
      fontWeight: 600,
      fontSize: "1.3rem",
      lineHeight: 1,
    },
    carouselItemDateText: {
      fontFamily: font.primary,
      fontSize: "1rem",
      lineHeight: 1.1,
      color: color.tertiary.dark,
    },
  } as ExtendedTypographyOptions,
} as ThemeOptions);

export { theme, color, font };
