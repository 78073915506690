import { Section } from "../../../../components/root/layout/section/Section";
import { Logo } from "../ui/Logo";
import { keyframes, styled, useTheme } from "@mui/material/styles";
import { Box, Checkbox, useMediaQuery } from "@mui/material";
import { color } from "../../../../assets/themes/theme";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const PrimaryNavigationNav = styled("nav")({
  display: "flex",
  alignItems: "center",
  minHeight: "35px"
});

const PrimaryNavigationUI = styled("ul")(({ theme }) =>({
  display: "flex",
  listStyleType: "none",
  gap: "1.5rem",
  paddingInlineStart: "10px",
  paddingInlineEnd: "10px",
  [theme.breakpoints.up("xl")]: {
    gap: "1.7rem",
  },
}));

const PrimaryAnchor = styled("a")(({ theme }) => ({
  height: 15,
  marginRight: theme.spacing(1),
  color: color.grayTone.supplemental,
  textDecoration: "none",
  width: "100%",
  display: "flex",
  alignItems: "center",
}));

const PrimaryText = styled("span")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: "1.25rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.4rem",
  },
  color: color.grayTone.subTitle,
  whiteSpace: "nowrap",
}));

const SecondaryNavButton = styled(NavLink)(({ theme }) => ({
  background: "transparent",
  border: "None",
  textDecoration: "none",
  cursor: "pointer",
  "&::after": {
    [theme.breakpoints.up("lg")]: {
      content: '""',
      display: "block",
      width: "100%",
      height: "2px",
      bottom: "0px",
      left: "0px",
      backgroundColor: color.grayTone.white,
      visibility: "hidden",
      transform: "scaleX(0)",
      "@media (prefers-reduced-motion: no-preference)": {
        transition: "all 0.4s ",
      },
    },
  },
  "&:hover::after": {
    transform: "scaleX(1)",
    visibility: "visible",
  },
}));

const SecondaryButtonText = styled("span")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontWeight: 500,
  fontSize: "1.125rem",
  color: color.text.primary,
}));

const topBarFrames = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
  to {
    transform-origin: left top;
    transform: rotate(35deg) scale(1.2, 1);
  }
`;

const bottomBarFrames = keyframes`
  from {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
  to {
    transform-origin: bottom left;
    transform: rotate(-35deg) scale(1.2, 1);
  }
`;

const topBarReverseFrames = keyframes`
  from {
    transform-origin: left top;
    transform: rotate(35deg) scale(1.2, 1);
  }
  to {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
`;

const bottomBarReverseFrames = keyframes`
	from {
    transform-origin: bottom left;
    transform: rotate(-35deg) scale(1.2, 1);
  }
  to {
    transform-origin: center;
    transform: rotate(0deg) scale(1, 1);
  }
`;

export function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const [isTopBarOpen, setIsTopBarOpen] = useState(false);

  const [displayOpacity, setDisplayOpacity] = useState<{ [key: number]: { display?: string; opacity?: number } }>({
    0: { display: "none", opacity: 0 },
    1: { display: "none", opacity: 0 },
    2: { display: "none", opacity: 0 },
    3: { display: "none", opacity: 0 },
    4: { display: "none", opacity: 0 },
    5: { display: "none", opacity: 0 },
  });

  const noPreferenceForReducedMotion = useMediaQuery("(prefers-reduced-motion: no-preference)");

  const handleTopBarOpenToggle = () => {
    setIsTopBarOpen(!isTopBarOpen);

    if (!isTopBarOpen) {
      setDisplayOpacity({
        0: { display: "block", opacity: 0 },
        1: { display: "block", opacity: 0 },
        2: { display: "block", opacity: 0 },
        3: { display: "block", opacity: 0 },
        4: { display: "block", opacity: 0 },
        5: { display: "block", opacity: 0 },
      });

      setTimeout(() => {
        setDisplayOpacity({
          0: { opacity: 1 },
          1: { opacity: 1 },
          2: { opacity: 1 },
          3: { opacity: 1 },
          4: { opacity: 1 },
          5: { opacity: 1 },
        });
      }, 10);
    } else {
      setDisplayOpacity({
        0: { opacity: 0 },
        1: { opacity: 0 },
        2: { opacity: 0 },
        3: { opacity: 0 },
        4: { opacity: 0 },
        5: { opacity: 0 },
      });

      setTimeout(
        () => {
          setDisplayOpacity({
            0: { display: "none" },
            1: { display: "none" },
            2: { display: "none" },
            3: { display: "none" },
            4: { display: "none" },
            5: { display: "none" },
          });
        },
        noPreferenceForReducedMotion ? 250 : 0
      );
    }
  };

  return (
    <>
      <Section className="grid grid-flow-col">
        <Logo />
        {lgDown ? (
          <span className="flex ml-auto items-center">
            {/* <PrimaryAnchor
              aria-label="login"
              href="/login"
              onClick={() => {
                return true;
              }}
            >
              <PersonIcon fontSize="small" />
              <PrimaryIconText>Login</PrimaryIconText>
            </PrimaryAnchor> */}

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                margin: "5px 10px",
                padding: "10px 10px",
                zIndex: "5",
                background: color.grayTone.white,
                gap: "10px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "auto",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    position: "relative",
                  }}
                >
                  <Checkbox
                    id="checkbox"
                    inputProps={{
                      "aria-label": "toggle menu on mobile",
                    }}
                    checked={isTopBarOpen}
                    sx={{
                      position: "absolute",
                      cursor: "pointer",
                      fontSize: 50,
                      opacity: 0,
                      zIndex: 1,
                      "&.Mui-checked": {
                        "& + div:first-of-type": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${topBarFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                          "@media (prefers-reduced-motion: reduce )": {
                            transformOrigin: "left top",
                            transform: "rotate(35deg) scale(1.2, 1)",
                          },
                        },
                        "& + div:first-of-type + div": {
                          opacity: 0,
                        },
                        "& + div:first-of-type + div + div": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${bottomBarFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                          "@media (prefers-reduced-motion: reduce)": {
                            transformOrigin: "bottom left",
                            transform: "rotate(-35deg) scale(1.2, 1)",
                          },
                        },
                      },
                      "&:not(.Mui-checked)": {
                        opacity: 0,
                        "& + div:first-of-type": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${topBarReverseFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                        },
                        "& + div:first-of-type + div": {
                          opacity: 1,
                        },
                        "& + div:nth-of-type + div + div": {
                          "@media (prefers-reduced-motion: no-preference)": {
                            animation: `${bottomBarReverseFrames} 500ms`,
                            animationFillMode: "forwards",
                          },
                        },
                      },
                    }}
                    onClick={() => {
                      handleTopBarOpenToggle();
                    }}
                  />
                  <Box
                    sx={{
                      borderBottom: `2px solid ${color.tertiary.dark}`,
                      width: "20px",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: `2px solid ${color.tertiary.dark}`,
                      width: "20px",
                      "@media (prefers-reduced-motion: no-preference)": {
                        transition: "opacity 500ms",
                      },
                    }}
                  ></Box>
                  <Box
                    sx={{
                      borderBottom: `2px solid ${color.tertiary.dark}`,
                      width: "20px",
                    }}
                  ></Box>
                </Box>
              </Box>
            </Box>
          </span>
        ) : (
          <span className="flex">
            <PrimaryNavigationNav aria-label="desktop navigation">
              <PrimaryNavigationUI>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    onClick={() => {
                      if (location.pathname === "/") {
                        window.location.hash = "#overview";
                        return document && document.getElementById("overview")!.scrollIntoView({ behavior: "smooth" });
                      } else {
                        navigate("/#overview");
                      }
                    }}
                  >
                    <PrimaryText>Overview</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    onClick={() => {
                      if (location.pathname === "/") {
                        window.location.hash = "#topperformers";
                        return document && document.getElementById("topperformers")!.scrollIntoView({ behavior: "smooth" });
                      } else {
                        navigate("/#topperformers");
                      }
                    }}
                  >
                    <PrimaryText>Top Performers</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    onClick={() => {
                      if (location.pathname === "/") {
                        window.location.hash = "#planprofiles";
                        return document && document.getElementById("planprofiles")!.scrollIntoView({ behavior: "smooth" });
                      } else {
                        navigate("/#planprofiles");
                      }
                    }}
                    aria-label="casestudies"
                  >
                    <PrimaryText>Plan Profiles </PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    onClick={() => {
                      if (location.pathname === "/") {
                        window.location.hash = "#webinars";
                        return document && document.getElementById("webinars")!.scrollIntoView({ behavior: "smooth" });
                      } else {
                        navigate("/#webinars");
                      }
                    }}
                  >
                    <PrimaryText>Webinars</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    aria-label="about hedis"
                    href="https://www.ncqa.org/hedis/"
                    onClick={() => {
                      return true;
                    }}
                  >
                    <PrimaryText>About HEDIS</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    href="/methodology"
                  >
                    <PrimaryText>Methodology</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    href="/health-equity"
                  >
                    <PrimaryText>Health Equity</PrimaryText>
                  </PrimaryAnchor>
                </li>
                <li>
                  <PrimaryAnchor
                    className="cursor-pointer"
                    href="/additional-resources"
                  >
                    <PrimaryText>Additional Resources</PrimaryText>
                  </PrimaryAnchor>
                </li>
              </PrimaryNavigationUI>
            </PrimaryNavigationNav>
          </span>
        )}
      </Section>
      <nav>
        {lgDown && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              zIndex: "5",
              background: color.grayTone.white,
              gap: "10px",
            }}
            className="ml-5 mb-5"
          >
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[0].display,
                opacity: displayOpacity[0].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to=""
                onClick={() => {
                  if (location.pathname === "/") {
                    window.location.hash = "#overview";
                    return document && document.getElementById("overview")!.scrollIntoView({ behavior: "smooth" });
                  } else {
                    navigate("/#overview");
                  }
                }}
              >
                <SecondaryButtonText>Overview</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[1].display,
                opacity: displayOpacity[1].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to=""
                onClick={() => {
                  if (location.pathname === "/") {
                    window.location.hash = "#topperformers";
                    return document && document.getElementById("topperformers")!.scrollIntoView({ behavior: "smooth" });
                  } else {
                    navigate("/#topperformers");
                  }
                }}
              >
                <SecondaryButtonText>Top Performers</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[2].display,
                opacity: displayOpacity[2].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to=""
                onClick={() => {
                  if (location.pathname === "/") {
                    window.location.hash = "#planprofiles";
                    return document && document.getElementById("planprofles")!.scrollIntoView({ behavior: "smooth" });
                  } else {
                    navigate("/#planprofiles");
                  }
                }}
              >
                <SecondaryButtonText>Plan Profiles</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[3].display,
                opacity: displayOpacity[3].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to=""
                onClick={() => {
                  if (location.pathname === "/") {
                    window.location.hash = "#webinars";
                    return document && document.getElementById("webinars")!.scrollIntoView({ behavior: "smooth" });
                  } else {
                    navigate("/#webinars");
                  }
                }}
              >
                <SecondaryButtonText>Webinars</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[5].display,
                opacity: displayOpacity[5].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                aria-label="about hedis"
                title="about hedis"
                to=""
                onClick={() => {
                  window.open("https://www.ncqa.org/hedis/", "_blank");
                  handleTopBarOpenToggle();
                }}
              >
                <SecondaryButtonText>About HEDIS</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[3].display,
                opacity: displayOpacity[3].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to="/methodology"
              >
                <SecondaryButtonText>Methodology</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[3].display,
                opacity: displayOpacity[3].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to="/health-equity"
              >
                <SecondaryButtonText>Health Equity</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
            <Box
              sx={{
                padding: "10px 0 0 0",
                display: displayOpacity[3].display,
                opacity: displayOpacity[3].opacity,
                "@media (prefers-reduced-motion: no-preference)": {
                  transition: "opacity 500ms",
                },
              }}
            >
              <SecondaryNavButton
                to="/additional-resources"
              >
                <SecondaryButtonText>Additional Resources</SecondaryButtonText>
              </SecondaryNavButton>
            </Box>
          </Box>
        )}
      </nav>
    </>
  );
}
