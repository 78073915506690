import { styled } from "@mui/material";

const StyledDiv = styled("div")({
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: "87.5rem",
});
export function Section({ children, className, style }: { children: React.ReactNode; className?: string; style?: React.CSSProperties }) {
  return (
    <StyledDiv style={style} className={className}>
      {children}
    </StyledDiv>
  );
}
