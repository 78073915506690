import { useMediaQuery } from "@mui/material";
import LogoMain from "../../../../assets/images/logo.svg";
import LogoInverse from "../../../../assets/images/logo-inverse.svg";
import LogoMobile from "../../../../assets/images/logo-mobile.svg";
import { styled, useTheme } from "@mui/material/styles";
interface LogoProps {
  inverse?: boolean;
}

const StyledLogo = styled("img")({
  objectFit: "cover",
});

const StyledButton = styled("button")({
  background: "none",
  border: "none",
  cursor: "pointer",
});
const Logo: React.FC<LogoProps> = ({ inverse, ...props }) => {
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <span>
      <StyledButton aria-label="NCQA home page" title="NCQA home page">
        <StyledLogo src={lgUp ? (inverse ? LogoInverse : LogoMain) : LogoMobile} alt="NCQA's logo" />
      </StyledButton>
    </span>
  );
};

export { Logo };
