import { Route, Routes } from "react-router-dom";
import React from "react";
const WebinarAsync = React.lazy(() => import("../pages/Webinar").then((module) => ({ default: module.Webinar })));
const CaseStudyAsync = React.lazy(() => import("../pages/CaseStudy").then((module) => ({ default: module.CaseStudy })));
const DetailAsync = React.lazy(() => import("../pages/Detail").then((module) => ({ default: module.Detail })));
const HomeAsync = React.lazy(() => import("./Home").then((module) => ({ default: module.Home })));
const LayoutAsync = React.lazy(() => import("./Layout").then((module) => ({ default: module.Layout })));
const NotFoundAsync = React.lazy(() => import("../pages/NotFound").then((module) => ({ default: module.NotFound })));
const Overview = React.lazy(() => import("../pages/Overview").then((module) => ({ default: module.Overview })));
const Methodology = React.lazy(() => import("../pages/Methodology").then((module) => ({ default: module.Methodology })));
const AdditionalResources = React.lazy(() => import("../pages/AdditionalResources").then((module) => ({ default: module.AdditionalResources })));
const HealthEquity = React.lazy(() => import("../pages/HealthEquity").then((module) => ({ default: module.HealthEquity})));
const PlanProfile1 = React.lazy(() => import("../pages/PlanProfile1").then((module) => ({ default: module.PlanProfile1 })));
const PlanProfile2 = React.lazy(() => import("../pages/PlanProfile2").then((module) => ({ default: module.PlanProfile2 })));
const PlanProfile3 = React.lazy(() => import("../pages/PlanProfile3").then((module) => ({ default: module.PlanProfile3 })));
const PlanProfile4 = React.lazy(() => import("../pages/PlanProfile4").then((module) => ({ default: module.PlanProfile4 })));
const PlanProfile5 = React.lazy(() => import("../pages/PlanProfile5").then((module) => ({ default: module.PlanProfile5 })));
export function Main() {
  return (
    <Routes>
      <Route path="/*" element={<LayoutAsync />}>
        <Route index element={<HomeAsync testing={false} />} />
        <Route path="webinars/:id" element={<WebinarAsync />} />
        <Route path="plan-profiles/:id" element={<CaseStudyAsync />} />
        <Route path="detail/:id" element={<DetailAsync />} />
        <Route path="overview" element={<Overview />} />
        <Route path="methodology" element={<Methodology />} />
        <Route path="additional-resources" element={<AdditionalResources />} />
        <Route path="health-equity" element={<HealthEquity />} />

        <Route path="planprofile1" element={<PlanProfile1 />} />
        <Route path="planprofile2" element={<PlanProfile2 />} />
        <Route path="planprofile3" element={<PlanProfile3 />} />
        <Route path="planprofile4" element={<PlanProfile4 />} />
        <Route path="planprofile5" element={<PlanProfile5 />} />
        <Route path="*" element={<NotFoundAsync />} />
      </Route>
    </Routes>
  );
}
